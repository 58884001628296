import { useEffect } from 'react';
import { NextRouter, useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { GEMINI_PIXEL_ID, GEMINI_PROJECT_ID } from '@lib';
import '../styles/globals.css';
import '../styles/redirect-loading.css';
import * as process from 'process';
import Script from 'next/script';
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';

export default function MyApp({ Component, pageProps }: AppProps) {
  const router: NextRouter = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // Use sendGTMEvent to track page views
      sendGTMEvent({
        event: 'pageview',
        page: url,
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: '(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function(){a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};a.queue=[];var s=\'script\';r=t.createElement(s);r.async=!0;r.src=n;var u=t.getElementsByTagName(s)[0];u.parentNode.insertBefore(r,u);})(window,document,\'https://sc-static.net/scevent.min.js\');window.snaptr(\'init\', \'49f6e9b7-f415-4057-959c-c34bd149ef56\');window.snaptr(\'track\', \'PAGE_VIEW\');',
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: '(function(w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};ttq.load(\'C9KDEG3C77UDKIA9768G\');ttq.page();})(window, document, \'ttq\');',
          }}
        />

        { /* quora does not work on localhost */}
        {process.env.NODE_ENV !== 'development' && (
          <>
            <script
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line max-len
                __html: '!function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, \'script\', \'https://a.quora.com/qevents.js\');qp(\'init\', \'d9bd35b7219740feb0c7dd5a1fad91d6\');qp(\'track\', \'ViewContent\');',
              }}
            />
            <noscript><img alt='quora' height='1' width='1' style={{ display: 'none' }} src='https://q.quora.com/_/ad/d9bd35b7219740feb0c7dd5a1fad91d6/pixel?tag=ViewContent&noscript=1' /></noscript>
          </>
        )}

        <script
          dangerouslySetInnerHTML={{
            __html: '!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt(\'init\',\'t2_sp20eshq\', {"optOut":false,"useDecimalCurrencyValues":true});rdt(\'track\', \'PageVisit\');',
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line max-len
            __html: `(function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':'${GEMINI_PROJECT_ID}','properties':{'pixelId':'${GEMINI_PIXEL_ID}'}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");`,
          }}
        />

        <script defer async src='https://analytics.growthhub.com/analytics.js' />

        <meta
          name='viewport'
          content='user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height'
        />
        <script type='text/javascript' async src='https://widgets.outbrain.com/outbrain.js' />
      </Head>
      <GoogleTagManager gtmId='GTM-T2LMWLX' />
      <Script
        type='text/javascript'
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
        strategy='afterInteractive'
      />
      <Component {...pageProps} />
    </>
  );
}
